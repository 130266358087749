<template>
    <v-simple-table dense>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">Id</th>
                    <th class="text-left">Referência</th>
                    <th class="text-left">Nome</th>
                    <th class="text-left">Cor</th>
                    <th class="text-left">Tamanho</th>
                    <th class="text-left">Qtde</th>
                    <th class="text-left">Preço</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in selectedOrder.items" :key="index">
                    <td class="text-left grey--text">{{ index+1 }}</td>
                    <td class="text-left grey--text">{{ item.vd2produto }}</td>
                    <td class="text-left grey--text">{{ item.pr0desc | capitalize}}</td>
                    <td class="text-left grey--text">{{ item.cr1nome | capitalize }}</td>
                    <td class="text-left grey--text">{{ item.gr1desc | capitalize }}</td>
                    <td class="text-left grey--text">{{ item.vd3qtde }}</td>
                    <td class="text-left grey--text">
                        {{ item.vd5preco | money }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    computed: {
        selectedOrder: {
            get() {
                return this.$store.state.order.selectedOrder;
            },
        },
    },
};
</script>

<style>
</style>